import { StrictMode } from 'react';
import React from 'react';

import Login_page from './scripts/pages/Login_page';

import Header from './scripts/blocks/header';
import Footer from './scripts/blocks/footer';

import { Pager } from './scripts/objects/navigator';
import UI from './scripts/objects/UI';
import User from './scripts/objects/User';
import Requests from './scripts/objects/requests';

import './styles/header.css';
import './styles/footer.css';
import './styles/main.css';


export default class App{

    constructor(){
        this.pager = new Pager(this.render);
        global['ch_page'] = this.pager.change_page;
        global['R'] = new Requests();
        global['user'] = new User();
        global['UI'] = new UI();
    }

    render = (name_tag = 'My_page') => {
        global.user.check_auth().then(
            (status) => {
                if(!global.user.auth)
                {
                    global.root.render(
                            <StrictMode>
                                <div id='container' className='App'>
                                    { Login_page.render() }
                                </div>
                            </StrictMode>
                    );
                }
                else{
                    import('./scripts/pages/'+name_tag).then(
                        (smt) => {
                            let page = smt.default(),
                                height = window.innerHeight + 'px';

                            global.root.render(
                                <StrictMode>
                                    <Header menus={global.user.pages}/>
                                    <div id='container' className='App' height={height} >
                                        {page}
                                    </div>
                                    <Footer />
                                </StrictMode>
                            );
                            try{
                                document.getElementById('container').style.transform = 'scale(1, 1)';
                            }catch{}
                        }
                    );
                }
        });
    };
}