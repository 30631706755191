class Login_page{

    static check_form(event){
        event.preventDefault();
        let form = event.target,
            data = {
                login: form.login.value,
                password: form.password.value
            };

        global.user.get_auth(data);
    }

    static render()
    {
        return (
            <form onSubmit={Login_page.check_form} method='POST' className='login-form'>
                <h1>Авторизация</h1>
                <input name='login' type='email' required placeholder='Email' />
                <input name='password' type='password' required placeholder='password' />
                <button>Вход</button>
            </form>
        );
    }
}

export default Login_page;
