
class Pager{
    constructor(callback){
        this.callback = callback;
    }

    change_page = (event) => {
        let clicked_button = event.target,
            needle_page = clicked_button.getAttribute('data-page');

        this.callback(needle_page);
    };
}


export { Pager };