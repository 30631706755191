//import React from 'react';
//import ReactDOM from 'react-dom';

class UI{

    constructor(){
        this.default_loader = "<div class='loading'><div class='loader'></div></div>";
        this.current_loader = document.getElementById('popup');
    }

    open_loading = () => {
        this.current_loader.innerHTML = this.default_loader;
    };

    close_loading = () => {
        this.current_loader.innerHTML = '';
    };
}


export default UI;
