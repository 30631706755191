import Cookies from 'universal-cookie';

export default class Requests{

    constructor(){
        this.cookies_worker = new Cookies();
        this.headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        try{
            if(this.cookies_worker.get('session') != null & this.cookies_worker.get('access') != null)
            {
                this.headers['Authorization-Session'] = this.cookies_worker.get('session');
                this.headers['Authorization'] = 'Token ' + this.cookies_worker.get('access')
            }
        }catch{}
    }

    update_headers = () => {
        try{
            if(this.cookies_worker.get('session') != null & this.cookies_worker.get('access') != null)
            {
                this.headers['Authorization-Session'] = this.cookies_worker.get('session');
                this.headers['Authorization'] = 'Token ' + this.cookies_worker.get('access')
            }
        }catch{}
    }

    json_request = (link, data, method='POST') => {
        this.update_headers();
        let ctx = {method: method, headers: this.headers};
        if(method !== 'GET')
        {
            ctx['body'] = JSON.stringify(data);
        }
        return fetch(link, ctx)
        .then((response) => {return response.json()});
    };

    request_next = (link, data, func, method='POST',  append_data=null) => {
        this.update_headers();
        return fetch(link, {method: 'POST', body: JSON.stringify(data), headers: this.headers})
        .then((response) => {return response.json()})
        .then((json) => { func(json, append_data); });
    };

    get = (link) => {
        return this.json_request(link, {}, 'GET');
    };

    get_next = (link, callback,  append_data) => {
        return this.request_next(link, {}, callback, 'GET', append_data);
    };

    post(link, data){
        return this.json_request(link, data, 'POST');
    };

    async async_post(link, data){
        let data_response = await this.json_request(link, data, 'POST');
        return data_response;
    };

    post_to = (link, data, callback,  append_data) => {
        return this.request_next(link, data, callback, 'POST', append_data);
    };



}
