//import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import Config from './config';

global.config = new Config();

const root = ReactDOM.createRoot(document.getElementById('root'));
global['root'] = root;

global['app'] = new App();
global.app.render();
