import Cookies from 'universal-cookie';


class User{

    constructor(){
        this.auth = false;
        this.cookies_worker = new Cookies();
        this.check_auth();
        this.accesses = {};
    }

    get_auth = (data) => {
        global.R.post(global.config.base_url_api()+'/api/v1/user/auth/', {login: data.login, password: data.password}).then(
            (json) => {
                if(json.auth)
                {
                    this.cookies_worker.set('session', json.session_token, { path: '/' });
                    this.cookies_worker.set('access', json.access_token, { path: '/' });
                    this.check_auth();
                    console.log(global.app.render());
                    console.log('Welcome');
                }
                else{
                    alert(json.error.msg);
                }
            }
        )
    };

    async check_auth() {
        if(this.cookies_worker.get('session') != null & this.cookies_worker.get('access') != null)
        {
//            let auth_data = await global.R.post(global.config.base_url_api()+'/api/v1/user/');
            let auth_data = await global.R.async_post(global.config.base_url_api()+'/api/v1/user/');
            if(auth_data.auth)
            {
                this.accesses = auth_data.accesses;
                this.auth = true;
                this.pages = [];
                for(let page of auth_data.pages)
                {
                    let flag = true;

                    for(let p of this.pages)
                    {
                        if(p.html == page.html)
                        {
                            flag = false;
                            break;
                        }
                    }

                    if(flag)
                    {
                        this.pages.push(page);
                    }
                }
                return true;
            }else
            {
                this.auth = false;
                return false;
            }
        }
        else
        {
            this.auth = false;
            return false;
        }
    };

    check_access = (name_access) => {
        return Object.keys(this.accesses).includes(name_access);
    };

}


export default User;