

function Footer(){
    return (
        <footer>
            platform
        </footer>
    );
}

export default Footer;