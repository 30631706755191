import Cookies from 'universal-cookie';


class User{

    constructor(){
        this.auth = false;
        this.cookies_worker = new Cookies();
        this.check_auth();

    }

    get_auth = (data) => {
        global.R.post(global.config.base_url_api()+'/api/v1/user/auth/', {login: data.login, password: data.password}).then(
            (json) => {
                console.log(json);
                if(json.auth)
                {
                    this.cookies_worker.set('session', json.session_token, { path: '/' });
                    this.cookies_worker.set('access', json.access_token, { path: '/' });
                    this.check_auth();
                    console.log(global.app.render());
                    console.log('Welcome');
                }
                else{
                    alert(json.error.msg);
                }
            }
        )
    };

    async check_auth() {
        if(this.cookies_worker.get('session') != null & this.cookies_worker.get('access') != null)
        {
//            let auth_data = await global.R.post(global.config.base_url_api()+'/api/v1/user/');
            let auth_data = await global.R.async_post(global.config.base_url_api()+'/api/v1/user/');
            if(auth_data.auth)
            {
                this.auth = true;
                this.pages = auth_data.pages;
                return true;
            }else
            {
                this.auth = false;
                return false;
            }
        }
        else
        {
            this.auth = false;
            return false;
        }
    };

}


export default User;