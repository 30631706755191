
function Header(menus={}, pager=null)
{
    let menu = menus.menus.map(
        (e) => {
            let element = <button key={e.html} data-page={e.html} onClick={global['ch_page']}>{e.name}</button>;
            return element;
        }
    ),
        menu2 = menus.menus.map(
            (e) => {
                let element = <button className="navbtn" key={e.html} data-page={e.html} onClick={global['ch_page']}>{e.name}</button>;
                return element;
            }
        ),
        is_open = true;

    let default_nav_style = {
        transform: 'translate(-100%, 0)', transition: '.2s linear',
        display: 'flex', flexDirection: 'row', width: '100%',
        position: 'fixed', left: 0, top: '70px', bottom: 0,
        color: '#000', background: 'white'
    },
        style_nav_menu = {
            display: 'flex', flexDirection: 'column', textShadow: '1px 1px black',
            '-webkit-text-fill-color': 'rgba(0,0,0,1)', color: 'black',
            padding: '15px', width: '100%'
    };

    menu = <div id="menu-choose" className='menu-choose'>
                {menu}
            </div>;
    menu2 = <div id="menu-choose2" style={style_nav_menu} className='menu-choose2'>
                {menu2}
            </div>;

    const change_menu = (e) => {
        let menu = document.getElementById('menu-choose'),
            menu_btn = document.getElementById('hamburger'),
            nav_node = document.getElementById('navigation');

        if(!is_open)
        {
            is_open = true;
            menu.style.transform = 'scaleX(1) translate(0)';
            menu.style.overflowX = 'scroll';

            menu_btn.style.transform = 'rotate(0)';

            let elements_hr = Array.from(menu_btn.getElementsByTagName('hr'));
            elements_hr[0].style.width = '100%';
            elements_hr[1].style.width = '100%';
            elements_hr[2].style.width = '100%';
            elements_hr[2].style.transform = 'translate(0, 0)  rotate(0)';
            elements_hr[0].style.transform = 'translate(0, 0) rotate(0deg)';

            nav_node.style.transform = 'translate(-100%, 0)';
        }
        else
        {
            is_open = false;
            menu.style.transform = 'scaleX(0)  translate(500px, 0)';
            menu.style.overflow = 'hidden';
            menu_btn.style.transform = 'rotate(45deg)';

            let elements_hr = Array.from(menu_btn.getElementsByTagName('hr'));
            elements_hr[0].style.width = '100%';
            elements_hr[0].style.transform = 'translate(0, 17px) rotate(90deg)';
            elements_hr[1].style.width = '100%';
            elements_hr[2].style.width = '25%';
            elements_hr[2].style.transform = 'translate(17px, 0px)  rotate(135deg)';

            nav_node.style.transform = 'translate(0, 0)';
        }
    };

    let style_of_action_menu = {
            width: '50px', height: '50px',
            display: 'flex', flexDirection: 'row',
            transform: 'rotate(0)', transition: '.1s linear'
    },
        style_of_hamburger = {
               width: '50px', marginTop: 0,
    };

    return (
        <header key={Math.random}>
            <button id="hamburger" onClick={change_menu} style={style_of_action_menu}>
                <div style={style_of_hamburger}>
                    <hr />
                    <hr />
                    <hr />
                </div>
            </button>
            {menu}
            <nav style={default_nav_style} id='navigation' >
                {menu2}
            </nav>
        </header>
    );
}

export default Header;
