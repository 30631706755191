// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer{
    position: fixed;
    bottom: 0; left: 0; right: 0;
    height: 15px;
    text-align: center; padding: 5px;
    border-radius: 15px 15px 0 0;
    border-top: 1px solid black;
    background-color: white;
    transition: .2s linear;
    box-shadow: 0 -10px 10px rgba(0,0,0,0.1);
}

footer:hover{
    height: 150px;
    box-shadow: 0 -20px 20px rgba(0,0,0,0.5);
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS,EAAE,OAAO,EAAE,QAAQ;IAC5B,YAAY;IACZ,kBAAkB,EAAE,YAAY;IAChC,4BAA4B;IAC5B,2BAA2B;IAC3B,uBAAuB;IACvB,sBAAsB;IACtB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,wCAAwC;AAC5C","sourcesContent":["footer{\n    position: fixed;\n    bottom: 0; left: 0; right: 0;\n    height: 15px;\n    text-align: center; padding: 5px;\n    border-radius: 15px 15px 0 0;\n    border-top: 1px solid black;\n    background-color: white;\n    transition: .2s linear;\n    box-shadow: 0 -10px 10px rgba(0,0,0,0.1);\n}\n\nfooter:hover{\n    height: 150px;\n    box-shadow: 0 -20px 20px rgba(0,0,0,0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
